import React from "react";
import styled from "styled-components";

import { palette, screens } from "../../../../utils";
import { ReactComponent as Refresh } from '../../../../assets/icons/refresh.svg';

export const Market = () => {
  return (
    <Container>
      <Title>Market</Title>

      <Grid>
        <Item>
          <IconWrap><Icon><Refresh/></Icon></IconWrap>
          <ItemTitle>Blockchain Technology Market</ItemTitle>
          <ItemText>Projected to reach USD 29 billion in 2024, covering applications beyond cryptocurrencies, like
            supply chain management, healthcare, and financial services.</ItemText>
        </Item>

        <Item>
          <IconWrap><Icon><Refresh/></Icon></IconWrap>
          <ItemTitle>Cryptocurrency Market</ItemTitle>
          <ItemText>Total market capitalisation is approximately USD 2.5 trillion, including major cryptocurrencies like
            Bitcoin and Ethereum.</ItemText>
        </Item>

        <Item>
          <IconWrap><Icon><Refresh/></Icon></IconWrap>
          <ItemTitle>Web 3.0 Blockchain Market</ItemTitle>
          <ItemText>Valued at USD 2 billion in 2021, projected to reach USD 53 billion by 2030, with a CAGR of 44.8%
            from 2022 to 2030, driven by decentralised data ownership, enhanced transparency, and high-security
            measures.</ItemText>
        </Item>
      </Grid>
    </Container>
  )
}

const Container = styled.div`
  margin: 0 20px;
  display: flex;
  flex-direction: column;
  gap: 40px;

  @media (min-width: ${screens.desktop}) {
    margin: 0 60px 130px;
    gap: 82px;
  }
`

const Title = styled.div`
  color: ${palette.black};
  font-weight: 600;
  font-size: 2em;

  @media (min-width: ${screens.desktop}) {
    font-size: 3.625em;
    font-weight: 700;
  }
`

const Grid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;

  @media (min-width: ${screens.desktop}) {
    flex-direction: row;
    justify-content: space-between;
  }
`

const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  @media (min-width: ${screens.desktop}) {
    gap: 0;
    width: 358px;
  }
`

const IconWrap = styled.div`
  background: ${palette.gray2};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;

  @media (min-width: ${screens.desktop}) {
    width: 100px;
    height: 100px;
    margin-bottom: 24px;
  }
`

const Icon = styled.div`
  width: 34px;
  height: 30px;

  @media (min-width: ${screens.desktop}) {
    width: 52px;
    height: 42px;
  }
`

const ItemTitle = styled.p`
  color: ${palette.black};
  font-weight: 700;
  font-size: 1.25em;
  text-align: center;

  @media (min-width: ${screens.desktop}) {
    font-size: 1.5em;
    margin-bottom: 20px;
  }
`

const ItemText = styled.p`
  color: ${palette.black};
  font-size: 0.875em;
  line-height: 16px;
  text-align: center;
  max-width: 60%;

  @media (min-width: ${screens.desktop}) {
    font-size: 1em;
    line-height: 20px;
    width: unset;
  }
`
