import React from "react";
import styled from "styled-components";

import { palette, screens } from "../../utils";
import { ReactComponent as Logo } from '../../assets/img/logo.svg';

import { NavMobile } from "./NavMobile";

export const Header = () => {
  return (
    <Container>
      <LogoWrap><Logo/></LogoWrap>

      <Navigation>
        <NavItem href='#product'>Technology</NavItem>
        <NavItem href='#solutions'>Global Force</NavItem>
        <NavItem href='#about'>About Us</NavItem>
        <NavItem href='#contact'>Contact</NavItem>
        <NavItem
          href='https://globalforce.io/dashboard'
          target="_blank"
          rel="noreferrer noopener"
        >
          Explorer
        </NavItem>
        <NavItem
          href='https://wiki.globalforce.io/en/'
          target="_blank"
          rel="noreferrer noopener"
        >
          Wiki
        </NavItem>
      </Navigation>

      <MobileMenu>
        <NavMobile/>
      </MobileMenu>
    </Container>
  )
}

const Container = styled.div`
  padding: 14px 0;
  margin: 0 20px;
  border-bottom: 0.49px solid ${palette.darkGray};
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1400px;

  @media (min-width: ${screens.desktop}) {
    margin: 0 60px;
    border-bottom: 1px solid ${palette.darkGray};
  }
`

const LogoWrap = styled.div`
  width: 52px;
  height: 28px;

  @media (min-width: ${screens.desktop}) {
    width: 70px;
    height: 36px;
  }
`

const Navigation = styled.div`
  display: none;
  gap: 30px;

  @media (min-width: ${screens.desktop}) {
    display: flex;
  }
`

const NavItem = styled.a`
  color: ${palette.black};
  font-weight: 500;

  &:hover {
    cursor: pointer;
    opacity: .5;
    transition: .3s;
  }
`

const MobileMenu = styled.div`
  @media (min-width: ${screens.desktop}) {
    display: none;
  }
`
