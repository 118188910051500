import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { palette } from "../../../utils";
import { ReactComponent as Linkedin } from "../../../assets/icons/linkedin.svg";
import { ReactComponent as X } from "../../../assets/icons/X.svg";
import { ReactComponent as Telegram } from "../../../assets/icons/telegram.svg";

export const NavMobile = () => {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    document.body.style.overflow = open ? 'hidden' : 'auto'
  }, [open])

  return (
    <div>
      <div onClick={() => setOpen(!open)}>
        <BarUp $open={open}/>
        <BarMid $open={open}/>
        <BarDown $open={open}/>
      </div>

      <SideMenu $open={open}>
        <NavBlock>
          <NavItem href='#product' onClick={() => setOpen(false)}>Technology</NavItem>
          <NavItem href='#solutions' onClick={() => setOpen(false)}>Global Force</NavItem>
          <NavItem href='#about' onClick={() => setOpen(false)}>About Us</NavItem>
          <NavItem href='#contact' onClick={() => setOpen(false)}>Contact</NavItem>
          <NavItem
            href='https://globalforce.io/dashboard'
            target="_blank"
            rel="noreferrer noopener"
          >
            Explorer
          </NavItem>
          <NavItem
            href='https://wiki.globalforce.io/en/'
            target="_blank"
            rel="noreferrer noopener"
          >
            Wiki
          </NavItem>
        </NavBlock>

        <LinksBox>
          <LinkWrap>
            <Link
              href='https://www.linkedin.com/company/globalforce-io/about/?viewAsMember=true'
              target="_blank"
              rel="noreferrer noopener"
            ><Linkedin/></Link></LinkWrap>
          <LinkWrap>
            <Link
              style={{ marginTop: '4px' }}
              href='https://x.com/global_force_io'
              target="_blank"
              rel="noreferrer noopener"
            ><X/></Link></LinkWrap>
          <LinkWrap>
            <Link
              style={{ marginTop: '4px', marginRight: '2px' }}
              href='https://t.me/globalforceio'
              target="_blank"
              rel="noreferrer noopener"
            >
              <Telegram/>
            </Link>
          </LinkWrap>
          <LinkWrap>
            <Link
              style={{ marginTop: '4px', marginRight: '2px' }}
              href='https://t.me/+yrwtEHp_l2FkOTcy'
              target="_blank"
              rel="noreferrer noopener"
            >
              <Telegram/>
            </Link>
          </LinkWrap>
        </LinksBox>
      </SideMenu>
    </div>
  )
}

const Bar = styled.div<{ $open: boolean }>`
  width: 22px;
  height: 2px;
  background-color: ${palette.black};
  margin: 4px 0;
  transition: 0.2s;
`

const BarUp = styled(Bar)`
  transform: ${({ $open }) => ($open ? 'translate(0, 3px) rotate(-45deg)' : 'none')};
`

const BarMid = styled(Bar)`
  opacity: ${({ $open }) => ($open ? 0 : 1)};
`

const BarDown = styled(Bar)`
  transform: ${({ $open }) => ($open ? 'translate(0, -11px) rotate(45deg)' : 'none')};
  margin-top: ${({ $open }) => ($open ? '6px' : '0')};
`

const SideMenu = styled.div<{ $open: boolean }>`
  position: fixed;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100vh;
  background: ${palette.bg};
  padding: 88px;
  margin-top: 18px;
  display: ${({ $open }) => ($open ? 'flex' : 'none')};
  flex-direction: column;
  gap: 94px;
  align-items: center;
`

const NavBlock = styled.nav`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
`

const NavItem = styled.a`
  color: ${palette.black};
  font-weight: 500;
  font-size: 1.25em;

  &:hover {
    cursor: pointer;
    opacity: .5;
    transition: .3s;
  }
`

const LinksBox = styled.div`
  display: flex;
  gap: 4px;
`

const LinkWrap = styled.div`
  background: ${palette.black};
  border-radius: 8px;
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
    filter: brightness(.8);
    transition: .3s;
  }
`

const Link = styled.a`
  width: 20px;
`
