import React from "react";
import styled from "styled-components";

import { palette, screens } from "../../../../utils";
import cover from '../../../../assets/img/cover.png'

export const Solutions = () => {
  return (
    <Container id='solutions'>
      <Title>Global Force <TitleSlice>Solutions</TitleSlice></Title>

      <Cards>
        <Img src={cover} alt='cover'/>

        <Card>
          <CardTitle>Lowest and Predictable Fees</CardTitle>
          <CardText>Our fee structure allows for better cost planning for businesses and users.</CardText>
        </Card>
        <Card>
          <CardTitle>Unmatched Scalability</CardTitle>
          <CardText>This eliminates congestion and long waiting times.</CardText>
        </Card>
        <Card>
          <CardTitle>Enhanced Decentralisation</CardTitle>
          <CardText>Our unique RBPS (Randomised Block Producer Selection) consensus algorithm prevents centralisation
            and reduces the risk of “51% attacks”.</CardText>
        </Card>
        <Card>
          <CardTitle>Robust Security</CardTitle>
          <CardText>Advanced security measures and random selection of block producers protect the network.</CardText>
        </Card>
        <Card>
          <CardTitle>Advanced Interoperability</CardTitle>
          <CardText>Capabilities to communicate between blockchains enable seamless integration with other
            networks.</CardText>
        </Card>
        <Card>
          <CardTitle>User-friendly Interface</CardTitle>
          <CardText>Accessible and understandable development tools to facilitate adoption by users and
            businesses.</CardText>
        </Card>
        <Card>
          <CardTitle>Energy Efficiency</CardTitle>
          <CardText>Efficient consensus algorithms reduce energy consumption and environmental impact.</CardText>
        </Card>
        <Card>
          <CardTitle>Flexible Utility</CardTitle>
        </Card>
      </Cards>
    </Container>
  )
}

const Container = styled.div`
  margin: 0 20px;
  display: flex;
  flex-direction: column;
  gap: 40px;

  @media (min-width: ${screens.desktop}) {
    margin: 0 60px 150px;
    gap: 70px;
  }
`

const Title = styled.div`
  color: ${palette.black};
  font-weight: 700;
  font-size: 32px;
  width: 90%;

  @media (min-width: ${screens.desktop}) {
    font-size: 58px;
    width: 100%;
  }
`

const TitleSlice = styled.span`
  font-weight: 700;
  opacity: .3;
  color: ${palette.black};
  font-size: 32px;

  @media (min-width: ${screens.desktop}) {
    font-size: 58px;
  }
`

const Cards = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  row-gap: 10px;

  @media (min-width: ${screens.desktop}) {
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
  }
`

const Img = styled.img`
  height: 336px;
  border-radius: 10px;
  width: 100%;
  object-fit: cover;

  @media (min-width: ${screens.desktop}) {
    grid-column: 1 / 2 span;
    grid-row: 1 / 4 span;
    height: 100%;
  }

`

const Card = styled.div`
  background: ${palette.lightGray};
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  &:last-child {
    padding-bottom: 26px;
  }

  @media (min-width: ${screens.desktop}) {
    padding: 20px;
  }
`

const CardTitle = styled.p`
  color: ${palette.black};
  font-weight: 700;
  font-size: 1.25em;

  @media (min-width: ${screens.desktop}) {
    font-size: 1.5em;
  }
`

const CardText = styled.p`
  color: ${palette.black};
  font-size: 0.875em;
  line-height: 16px;

  @media (min-width: ${screens.desktop}) {
    font-size: 1em;
    line-height: 20px;
  }
`
