import React from "react";
import styled from "styled-components";

import { palette, screens } from "../../../../utils";

export const Challenges = () => {
  return (
    <Container>
      <TitleWrap>
        <Title>Challenges</Title>
        <Subtitle>in the Blockchain World</Subtitle>
      </TitleWrap>

      <Grid>
        <Item>
          <ItemTitle>High Transaction Costs</ItemTitle>
          <ItemText>Many blockchain platforms have unpredictable and high fees, making cost planning difficult for
            businesses.</ItemText>
        </Item>
        <Item>
          <ItemTitle>Limited Scalability</ItemTitle>
          <ItemText>Traditional blockchains struggle with handling large volumes of transactions, leading to long
            waiting times and network congestion.</ItemText>
        </Item>
        <Item>
          <ItemTitle>Centralisation of Power</ItemTitle>
          <ItemText>Consensus algorithms such as Proof of Stake can be vulnerable to centralisation, where a few actors
            control the network.</ItemText>
        </Item>
        <Item>
          <ItemTitle>Security Vulnerabilities</ItemTitle>
          <ItemText>Existing platforms are susceptible to “51% attacks”, hacks, andother security issues.</ItemText>
        </Item>
        <Item>
          <ItemTitle>Limited Interoperability</ItemTitle>
          <ItemText>Poor communication between different blockchains limits technology adoption and use.</ItemText>
        </Item>
        <Item>
          <ItemTitle>Complexity</ItemTitle>
          <ItemText>Current blockchain solutions can be complex and difficult to use for non-technical
            users and businesses.</ItemText>
        </Item>
        <Item>
          <ItemTitle>Environmental Impact</ItemTitle>
          <ItemText>Some consensus algorithms, like Proof of Work, have high energy consumption and a negative
            environmental impact.</ItemText>
        </Item>
      </Grid>
    </Container>
  )
}

const Container = styled.div`
  background: ${palette.black};
  padding: 46px 12px 60px 20px;
  display: flex;
  flex-direction: column;
  gap: 40px;

  @media (min-width: ${screens.desktop}) {
    border-radius: 10px;
    padding: 70px 60px 78px;
    gap: 74px;
    margin-bottom: 174px;
  }
`

const TitleWrap = styled.div`
  display: flex;
  flex-direction: column;
`

const Title = styled.div`
  color: ${palette.gray};
  font-weight: 700;
  font-size: 2em;

  @media (min-width: ${screens.desktop}) {
    font-size: 3.625em;
  }
`

const Subtitle = styled(Title)`
  color: ${palette.white};
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  row-gap: 20px;

  @media (min-width: ${screens.desktop}) {
    grid-template-columns: repeat(3, 1fr);
    row-gap: 0;
  }
`

const Item = styled.div`
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-bottom: 1px solid ${palette.darkGray};

  &:last-child {
    border: none;
  }

  @media (min-width: ${screens.desktop}) {
    padding: 20px 30px 20px;
    gap: 20px;
    justify-content: space-between;

    &:nth-child(1) {
      padding-top: 0;
    }

    &:nth-child(2) {
      border-left: 1px solid ${palette.darkGray};
      border-right: 1px solid ${palette.darkGray};
      padding-top: 0;
    }

    &:nth-child(3) {
      padding-top: 0;
    }

    &:nth-child(5) {
      border-left: 1px solid ${palette.darkGray};
      border-right: 1px solid ${palette.darkGray};
    }

    &:nth-child(7) {
      border-left: 1px solid ${palette.darkGray};
      border-right: 1px solid ${palette.darkGray};
      grid-column: 2;
      padding-bottom: 0;
    }
  }
`

const ItemTitle = styled.p`
  color: ${palette.turquoise};
  font-weight: 700;
  font-size: 1.25em;

  @media (min-width: ${screens.desktop}) {
    font-size: 1.5em;
  }
`

const ItemText = styled.p`
  color: ${palette.white};
  font-size: 0.875em;
  line-height: 16px;

  @media (min-width: ${screens.desktop}) {
    font-size: 1em;
    width: 88%;
  }
`
