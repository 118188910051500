import axios, { AxiosResponse } from "axios";

const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development'

const prodURL = 'https://www.swisstechcapital.com/api'
const devURL = '/api'

const axiosInstance = axios.create({
  baseURL: isDev ? devURL : prodURL,
  headers: {
    "Content-type": "application/json",
  },
})

export const sendForm = (body: any): Promise<AxiosResponse<any>> =>
  axiosInstance.post('/SendMail', body)
