import React from "react";
import styled from "styled-components";

import { palette } from "../../../utils";

interface ITextInput {
  value: string
  placeholder?: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
  style?: {}
}

export const TextInput = (
  {
    value,
    placeholder,
    onChange,
    style = {}
  }: ITextInput) => {

  return (
    <Input
      type='text'
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      style={{ ...style }}
    />
  )
}

const Input = styled.input`
  width: 100%;
  height: 34px;
  border: none;
  padding-bottom: 6px;
  background: transparent;
  border-bottom: 1px solid rgba(20, 20, 20, .5);
  font-size: 1em;

  &::placeholder {
    opacity: .5;
  }
`
