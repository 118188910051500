import React from 'react';
import styled from "styled-components";

import { Footer, Header } from "./components";
import { Main } from "./pages";

function App() {
  return (
    <Container>
      <Layout>
        <Header/>
        <Main/>
        <Footer/>
      </Layout>
    </Container>
  );
}

export default App;

const Container = styled.div`
  min-width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  box-sizing: border-box;
`

const Layout = styled.div`
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
`
