import React from "react";
import styled from "styled-components";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { palette, screens } from "../../utils";
import cover from '../../assets/img/cover.png'

import { About, Challenges, Contact, Market, Product, Solutions } from "./components";

export const Main = () => {
  return (
    <Container>
      <Cover>
        <Title>Swiss Tech Capital AG <TitleSlice>introduces</TitleSlice> Global Force</Title>

        <Img src={cover} alt='cover'/>
      </Cover>

      <Power>
        <PowerTitle><PowerTitleSlice>Freeing</PowerTitleSlice> the true power of Blockchain</PowerTitle>

        <PowerCards>
          <Card>
            <CardTitle>Vision</CardTitle>
            <CardText>To transform industries with the limitless potential of blockchain technology, creating a more
              transparent, and efficient world.</CardText>
          </Card>
          <Card>
            <CardTitle>Mission</CardTitle>
            <CardText>To deliver a decentralised blockchain technology and a blockchain platform that both
              are secure, efficient, and accessible to all users, regardless of their technical expertise or
              geographical location or industry.</CardText>
          </Card>
        </PowerCards>
      </Power>

      <Product/>
      <Challenges/>
      <Solutions/>
      <Market/>
      <About/>
      <GoogleReCaptchaProvider reCaptchaKey={"6LdlZBQqAAAAADFmU5H-mChf5T-G-iDT5wIb2o2x"}>
        <Contact/>
      </GoogleReCaptchaProvider>
    </Container>
  )
}

const Container = styled.div`
  flex: 1 0 auto;
  padding: 56px 0 32px;
  display: flex;
  flex-direction: column;
  gap: 60px;
  margin: 0 auto;
  max-width: 1520px;

  @media (min-width: ${screens.desktop}) {
    padding: 60px 0 72px;
    gap: 0;
  }
`

const Cover = styled.div`
  display: flex;
  flex-direction: column;
  gap: 56px;
  margin: 0 20px;

  @media (min-width: ${screens.desktop}) {
    gap: 40px;
    margin: 0 60px 124px;
  }
`

const Title = styled.div`
  color: ${palette.black};
  font-weight: 700;
  font-size: 42px;
  line-height: 34px;
  letter-spacing: -0.05em;
  max-width: 500px;

  @media (min-width: ${screens.desktop}) {
    max-width: unset;
    width: 80%;
    font-size: 100px;
    line-height: 80px;
  }
`

const TitleSlice = styled.span`
  font-weight: 600;
  opacity: .3;
  color: ${palette.black};
  line-height: 34px;
  font-size: 42px;

  @media (min-width: ${screens.desktop}) {
    font-size: 100px;
    line-height: 80px;
  }
`

const Img = styled.img`
  border-radius: 10px;
  height: 336px;
  object-fit: cover;
  width: 100%;

  @media (min-width: ${screens.desktop}) {
    height: 444px;
  }
`

const Power = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin: 0 20px;
  //width: 100%;

  @media (min-width: ${screens.desktop}) {
    gap: 60px;
    margin: 0 60px 208px;
  }
`

const PowerTitle = styled.div`
  color: ${palette.black};
  font-weight: 700;
  font-size: 32px;

  @media (min-width: ${screens.desktop}) {
    font-size: 58px;
    max-width: 582px;
  }
`

const PowerTitleSlice = styled.span`
  font-weight: 700;
  opacity: .3;
  color: ${palette.black};
  font-size: 32px;

  @media (min-width: ${screens.desktop}) {
    font-size: 58px;
  }
`

const PowerCards = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  row-gap: 10px;
  width: 100%;

  @media (min-width: ${screens.desktop}) {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;
  }
`

const Card = styled.div`
  background: ${palette.black};
  border-radius: 10px;
  padding: 28px 22px 36px;
  display: flex;
  flex-direction: column;
  gap: 42px;

  @media (min-width: ${screens.desktop}) {
    padding: 38px 40px 32px;
    gap: 200px;
    justify-content: space-between;
  }
`

const CardTitle = styled.p`
  color: ${palette.white};
  font-weight: 700;
  font-size: 2em;

  @media (min-width: ${screens.desktop}) {
    font-size: 2.75em;
  }
`

const CardText = styled.p`
  font-size: 1.125em;
  color: ${palette.white};
  line-height: 22px;

  @media (min-width: ${screens.desktop}) {
    font-size: 1.5em;
    line-height: 28px;
    width: 99%;
  }
`
