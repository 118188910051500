import React from "react";
import styled from "styled-components";

import { palette, screens } from "../../utils";
import { ReactComponent as Logo } from '../../assets/img/logo.svg';

export const Footer = () => {
  return (
    <Container>
      <LogoWrap><Logo/></LogoWrap>

      <Text>© {new Date().getFullYear()} by Swiss Tech Capital | Register UID CHE - 166.097.775</Text>
    </Container>
  )
}

const Container = styled.div`
  flex: 0 0 auto;
  margin: 0 20px;
  padding: 16px 0 14px;
  border-top: 0.49px solid ${palette.darkGray};
  display: flex;
  justify-content: space-between;
  max-width: 1400px;

  @media (min-width: ${screens.desktop}) {
    margin: 0 60px;
    border-top: 1px solid ${palette.darkGray};
    padding: 20px 0 24px;
    align-items: center;
  }
`

const LogoWrap = styled.div`
  width: 52px;
  height: 28px;

  @media (min-width: ${screens.desktop}) {
    width: 70px;
    height: 36px;
  }
`

const Text = styled.p`
  font-size: 0.875em;
  color: ${palette.black};
  width: 230px;
  text-align: center;
  letter-spacing: 0.01em;

  @media (min-width: ${screens.desktop}) {
    width: unset;
  }
`
