import React from "react";
import styled from "styled-components";

import { palette, screens } from "../../../../utils";
import founder from '../../../../assets/img/founder.jpeg'

export const About = () => {
  return (
    <Container id='about'>
      <Title>About Us</Title>
      <Content>
        <MainInfo>Our Founder, a true visionary and an internationally experienced Swiss financial expert as well as
          high-level advisor and early investor in multiple industries and sustainable ventures, discovered this unique
          blockchain architecture in 2018. He identified and guided an amasing team of inspired specialists to further
          develop what is now our principal product in order to offer our world leading, efficient, secure, and fast
          blockchain called Global Force.</MainInfo>

        <Img src={founder} alt='founder'/>

        <TextBlock>
          <Text>Swiss Tech Capital AG, a Zurich registered Swiss Company was originally founded in 2012. With the
            introduction of GLOBAL FORCE, our vision is to transform industries such as robotics, AI, DeFi, transport
            logistics, healthcare, and education with the limitless potential of blockchain technology, creating a more
            transparent and efficient world.</Text>
          <Text>We have also developed our own new generation video game. Our mission is to deliver a fully regulated,
            decentralised, blockchain technology and a blockchain platform that both are secure, efficient, and
            accessible
            to all users, regardless of their technical expertise or geographical location or industry.</Text>
        </TextBlock>

        <GrayCard>STC guaranties to reserve and dedicate a percentage of our profits to benefit sustainable, ecological,
          and humanitarian ventures.</GrayCard>

        <MainCard>
          <MainCardTitle>Platform development started in 2018.</MainCardTitle>
          <MainCardText>Test versions of historical servers and staking contracts launched. Successful launch of the
            main network in 2023.</MainCardText>
        </MainCard>
      </Content>
    </Container>
  )
}

const Container = styled.div`
  margin: 0 20px;
  display: flex;
  flex-direction: column;

  @media (min-width: ${screens.desktop}) {
    margin: 0 60px 150px;
  }
`

const Title = styled.div`
  color: ${palette.black};
  font-weight: 600;
  font-size: 2em;
  margin-bottom: 40px;

  @media (min-width: ${screens.desktop}) {
    font-size: 3.625em;
    font-weight: 700;
  }
`

const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  width: 100%;

  @media (min-width: ${screens.desktop}) {
    grid-template-columns: repeat(2, 1fr);
  }
`

const MainInfo = styled.p`
  color: ${palette.black};
  font-weight: 600;
  font-size: 1.5em;
  margin-bottom: 34px;

  @media (min-width: ${screens.desktop}) {
    font-size: 2.25em;
    line-height: 38px;
    grid-column: 1 / 2 span;
    text-indent: 50%;
    margin-bottom: 46px;
  }
`

const Img = styled.img`
  border-radius: 10px;
  margin-bottom: 34px;
  object-fit: cover;
  height: 90vw;
  object-position: top;
  width: 100%;

  @media (min-width: ${screens.desktop}) {
    width: 290px;
    height: 290px;
  }
`

const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 30px;

  @media (min-width: ${screens.desktop}) {
    margin-bottom: 42px;
  }
`

const Text = styled.p`
  color: ${palette.black};
  opacity: .7;
  font-size: 1.125em;
  font-weight: 500;

  @media (min-width: ${screens.desktop}) {
    font-size: 1.5em;
  }
`

const GrayCard = styled.div`
  background: ${palette.lightGray};
  border-radius: 10px;
  padding: 44px 34px 54px;
  color: ${palette.black};
  font-weight: 700;
  font-size: 1.25em;
  text-align: center;
  margin-bottom: 40px;

  @media (min-width: ${screens.desktop}) {
    grid-column: 2;
    padding: 96px 70px 116px;
    font-size: 1.5em;
    margin-bottom: 82px;
    text-align: unset;
  }
`

const MainCard = styled.div`
  background: ${palette.turquoise};
  border-radius: 10px;
  padding: 54px 20px 64px;
  display: flex;
  flex-direction: column;
  gap: 36px;
  align-items: center;

  @media (min-width: ${screens.desktop}) {
    padding: 84px 0 104px;
    gap: 26px;
    grid-column: 1 / 2 span;
  }
`

const MainCardTitle = styled.p`
  color: ${palette.black};
  font-weight: 600;
  font-size: 2em;
  text-align: center;

  @media (min-width: ${screens.desktop}) {
    font-size: 3.625em;
  }

`

const MainCardText = styled.p`
  color: ${palette.black};
  font-style: italic;
  font-size: 1.5em;
  text-align: center;

  @media (min-width: ${screens.desktop}) {
    font-size: 2.25em;
    width: 820px;
    max-width: unset;
  }
`
