import React from "react";
import styled from "styled-components";

import { palette } from "../../../utils";

interface ITextarea {
  value: string
  placeholder?: string
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void,
  style?: {}
}

export const Textarea = (
  {
    value,
    placeholder,
    onChange,
    style = {}
  }: ITextarea) => {

  return (
    <Container
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      style={{ ...style }}
    />
  )
}

const Container = styled.textarea`
  width: 100%;
  height: 88px;
  font-size: 1em;
  border: none;
  background: transparent;
  padding-bottom: 6px;
  resize: none;
  border-bottom: 1px solid rgba(20, 20, 20, .5);

  &::placeholder {
    opacity: .5;
  }
`
