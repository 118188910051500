import React from "react";
import styled from "styled-components";

import { palette } from "../../utils";

interface IButton {
  type?: 'button' | 'submit' | 'reset'
  children: React.ReactNode
  onClick?: () => void
  style?: any
}

export const Button: React.FC<IButton> =
  ({
     type,
     children,
     onClick,
     style
   }) => {
    return (
      <Container
        type={type}
        style={style}
        onClick={onClick}
      >
        {children}
      </Container>
    )
  }

Button.defaultProps = {
  type: 'button',
  style: {},
  onClick: undefined,
}

const Container = styled.button`
  width: 100%;
  border-radius: 42px;
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  font-size: 1em;
  font-weight: 600;
  background: ${palette.black};
  color: ${palette.white};

  &:hover {
    cursor: pointer;
    transition: .3s;
    filter: brightness(.8);
  }
`
