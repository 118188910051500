import React from "react";
import styled from "styled-components";

import { palette, screens } from "../../../../utils";

export const Product = () => {
  return (
    <Container id='product'>
      <Title><TitleSlice>Product</TitleSlice> and Technology</Title>

      <Info>
        <MainInfo>Global Force created a blockchain, based on EOSIO, open architecture, that can quickly process
          transactions while supporting a huge architecture of smart contracts, which allows our blockchain to be more
          useful for people in everyday life (“flexible utility”).</MainInfo>

        <TextBlock>
          <Text>Our unique RBPS (Randomised Block Producer Selection) consensus algorithm prevents centralisation and
            reduces the risk of “51% attacks”.</Text>
          <Text>We have developed our own security architecture, our own bridges (gf.swap), our own infrastructure, our
            own
            smart oracle contracts (gf.price), our own exchanges (gf.dex),</Text>
          <Text>our own system stakes ( gf.hold), our own unique NFT protocol (gf.nft), and much more. </Text>
        </TextBlock>
      </Info>

      <Cards>
        <CoverCard>Key Features</CoverCard>

        <Card>
          <CardTitle>Regulation</CardTitle>
          <CardText>STC is a Swiss registered and owned company founded in 2012. STC will be fully regulated by the
            respective Financial Authorities: the FINMA in Switzerland, MICA in the European Community and the SEC in
            the USA.</CardText>
        </Card>
        <Card>
          <CardTitle>Community and Ecosystem</CardTitle>
          <CardText>STC guaranties to reserve and dedicate a percentage of our profits to benefit sustainable,
            ecological and humanitarian ventures. We focus on building a supportive and active community of developers,
            businesses, and users, ensuring continuous improvement and innovation.</CardText>
        </Card>
        <Card>
          <CardTitle>RBPS Consensus Algorithm</CardTitle>
          <CardText>Random selection of block producers in accordance with our unique algorithm enhances security and
            mitigates the risk of “51% attacks”.</CardText>
        </Card>
        <Card>
          <CardTitle>Unmatched Transaction Speed</CardTitle>
          <CardText>Capable of processing at least 10,000 transactions per second which is faster by multiples than the
            nearest competitor. There are many blockchains, some are fast (Solana, EOS), but we combine speed,
            reliability, convenience, wide possibilities of use, legal aspects, and ample opportunities etc. in a
            complex cluster.</CardText>
        </Card>
        <Card>
          <CardTitle>Direct Bridging</CardTitle>
          <CardText>Our unique functional platform for our clients uses direct cross-chain
            transactions through the gf.swap smart contract, which significantly speeds up transactions. All wrapped
            tokens in the GF network have corresponding liquidity in the corresponding networks. This increases the
            security of using our bridge connections.</CardText>
        </Card>
        <Card>
          <CardTitle>Predictable Fee Structure</CardTitle>
          <CardText>Simplifies cost planning for businesses. Any transaction requires two types of resources, CPU and
            RAM, which have to be paid for. Each transaction consumes these resources depending on the size in bytes. In
            order to conduct transactions, these resources must be on your account balance. The value of resources is
            determined by the community and can only be changed by a general vote of our 21 block producers. Since the
            cost of resources will not change depending on factors other than the will of the community, developers have
            the opportunity to plan their costs in advance.</CardText>
        </Card>
        <Card>
          <CardTitle>WASM and EVM Compatibility</CardTitle>
          <CardText>Supports smart contracts in multiple programming languages.</CardText>
        </Card>
        <Card>
          <CardTitle>Global Force operates in a variety of areas</CardTitle>
          <CardText>Critical applications driving market growth such as artificial intelligence, data and transaction
            storage, and smart contracts. It is also built for the media and entertainment industry to enable
            decentralised content management and direct monetisation through P2P micropayments, payments, offering fast,
            secure and low-cost crossborder processing; and cryptocurrency, which is the main application of blockchain
            technology.</CardText>
        </Card>
      </Cards>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 20px;

  @media (min-width: ${screens.desktop}) {
    margin: 0 60px 150px;
  }
`

const Title = styled.div`
  color: ${palette.black};
  font-weight: 700;
  font-size: 32px;
  margin-bottom: 40px;

  @media (min-width: ${screens.desktop}) {
    font-size: 58px;
    margin-bottom: 90px;
  }
`

const TitleSlice = styled.span`
  font-weight: 700;
  opacity: .3;
  color: ${palette.black};
  line-height: 32px;
  font-size: 32px;

  @media (min-width: ${screens.desktop}) {
    font-size: 58px;
  }
`

const Info = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  row-gap: 40px;
  margin-bottom: 80px;

  @media (min-width: ${screens.desktop}) {
    row-gap: 46px;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 60px;
  }
`

const MainInfo = styled.p`
  font-weight: 600;
  color: ${palette.black};
  font-size: 1.5em;

  @media (min-width: ${screens.desktop}) {
    font-size: 2.25em;
    grid-column: 1 / 2 span;
    text-indent: 50%;
  }
`

const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (min-width: ${screens.desktop}) {
    grid-column: 2;
  }
`

const Text = styled.p`
  color: ${palette.black};
  font-weight: 500;
  font-size: 1.125em;
  opacity: .7;

  @media (min-width: ${screens.desktop}) {
    font-size: 1.5em;
  }
`

const Cards = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  row-gap: 10px;

  @media (min-width: ${screens.desktop}) {
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }
`

const CoverCard = styled.div`
  background: ${palette.turquoise};
  border-radius: 10px;
  padding: 68px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${palette.black};
  font-weight: 600;
  font-size: 2em;
  text-align: center;

  @media (min-width: ${screens.desktop}) {
    font-size: 2.625em;
    padding: 0 120px;
  }
`

const Card = styled.div`
  background: ${palette.lightGray};
  padding: 38px 24px 52px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 34px;

  @media (min-width: ${screens.desktop}) {
    padding: 38px 32px 36px;
    min-height: 354px;
    gap: 14px;
    justify-content: space-between;
  }
`

const CardTitle = styled.p`
  color: ${palette.black};
  font-weight: 700;
  font-size: 1.25em;

  @media (min-width: ${screens.desktop}) {
    font-size: 1.5em;
  }
`

const CardText = styled.p`
  color: ${palette.black};
  font-size: 0.875em;
  opacity: .7;
  line-height: 16px;

  @media (min-width: ${screens.desktop}) {
    font-size: 1em;
    line-height: 20px;
  }
`
