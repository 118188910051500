export const palette = {
  darkGray: '#4B4848',
  black: '#1A1E27',
  white: '#FFFFFF',
  turquoise: '#00EFEE',
  lightGray: '#EEEEEE',
  gray: '#A3A3A3',
  gray2: '#E2E2E2',
  bg: '#F3F3F3'
}
