import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { palette, screens } from "../../../../utils";
import { sendForm } from "../../../../api";
import { Button, Textarea, TextInput } from "../../../../components";
import { ReactComponent as Linkedin } from '../../../../assets/icons/linkedin.svg';
import { ReactComponent as X } from '../../../../assets/icons/X.svg';
import { ReactComponent as Telegram } from '../../../../assets/icons/telegram.svg';

export const Contact = () => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [message, setMessage] = useState('')
  const [token, setToken] = useState<string>('')

  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }

    const token = await executeRecaptcha('signup');
    setToken(token)
  }, [executeRecaptcha]);

  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  const sendData = async () => {
    if (token.length > 0) {
      await sendForm({
        email: email,
        msg: message,
        name: name,
        phone: phone,
        reCaptchaToken: token
      })
        .then((r) => {
          setName('')
          setPhone('')
          setEmail('')
          setMessage('')
        })
        .catch((err) => handleReCaptchaVerify())
    } else {
      return
    }
  }

  return (

    <Container id='contact'>
      <Title>Contact</Title>

      <Content>
        <LinksBox>
          <LinkWrap>
            <Link
              href='https://www.linkedin.com/company/globalforce-io/about/?viewAsMember=true'
              target="_blank"
              rel="noreferrer noopener"
            ><Linkedin/></Link></LinkWrap>
          <LinkWrap>
            <Link
              style={{ marginTop: '4px' }}
              href='https://x.com/global_force_io'
              target="_blank"
              rel="noreferrer noopener"
            ><X/></Link></LinkWrap>
          <LinkWrap>
            <Link
              style={{ marginTop: '4px', marginRight: '2px' }}
              href='https://t.me/globalforceio'
              target="_blank"
              rel="noreferrer noopener"
            >
              <Telegram/>
            </Link>
          </LinkWrap>
          <LinkWrap>
            <Link
              style={{ marginTop: '4px', marginRight: '2px' }}
              href='https://t.me/+yrwtEHp_l2FkOTcy'
              target="_blank"
              rel="noreferrer noopener"
            >
              <Telegram/>
            </Link>
          </LinkWrap>
        </LinksBox>

        <Form onSubmit={(e) => {
          e.preventDefault()
          sendData()
        }}>
          <TextInput
            placeholder='Your Name'
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextInput
            placeholder='Your e-mail'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextInput
            placeholder='Your phone'
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
          <Textarea
            placeholder='Your message'
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />

          <Button style={{ marginTop: '20px' }} type='submit'>submit</Button>
        </Form>
      </Content>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin: 0 20px;

  @media (min-width: ${screens.desktop}) {
    margin: 0 60px;
    gap: 70px;
  }
`

const Title = styled.div`
  color: ${palette.black};
  font-weight: 600;
  font-size: 2em;

  @media (min-width: ${screens.desktop}) {
    font-size: 3.625em;
    font-weight: 700;
  }
`

const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  row-gap: 30px;

  @media (min-width: ${screens.desktop}) {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 0;
  }
`

const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`

const MailBlock = styled.div`
  display: flex;
  gap: 10px;
  color: ${palette.black};
  line-height: 18px;

  @media (min-width: ${screens.desktop}) {
    width: 420px;
  }
`

const IconMail = styled.span`
  width: 20px;
  flex-shrink: 0;
`

const Mail = styled.a`
  color: ${palette.black};
  line-height: 18px;

  &:hover {
    opacity: .5;
    cursor: pointer;
    transition: .3s;
  }
`

const LinksBox = styled.div`
  display: flex;
  gap: 4px;
`

const LinkWrap = styled.div`
  background: ${palette.black};
  border-radius: 8px;
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
    filter: brightness(.8);
    transition: .3s;
  }
`

const Link = styled.a`
  width: 20px;
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
`
